@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.05rem;
    scroll-behavior: smooth;
}

:root {
    --primary: #1067da;
    --secondary: #dae8f5;
}

body {
    overflow-x: hidden;
}

.fade-enter {
    opacity: 0;
    transform: translateX(95px);
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
    opacity: 1;
    transform: translateX(0);
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translateX(85px);
    transition: opacity 250ms ease-out, transform 300ms ease;
}







/* ######## SCROLL BAR ######## */
body::-webkit-scrollbar{
    width: 10px;
}

body::-webkit-scrollbar-thumb{
    border-radius: 25px;
    background-color: var(--primary);
    transition: 0.7s;
}

body::-webkit-scrollbar-track{
    background-color: #b4cae6ee;
    border-radius: 10px;
}






/* ######## GLOBAL ######## */

a{
    text-decoration: none;
    font-weight: normal;
}

a:hover{
    color: var(--primary) !important;
}

img{
    transition: all .7s ease-in-out;
}

.color-primary{
    color: var(--primary);
}

.bgcolor-primary{
    background-color: var(--primary);
}

.bordercolor-primary{
    border-color: var(--primary) !important;
}

.color-secondary{
    color: var(--secondary);
}

.bgcolor-secondary{
    background-color: var(--secondary);
}

.fs-6l{
    font-size: 1.12rem;
}

.fs-9s{
    font-size: .9rem;
}
.fs-8s{
    font-size: .8rem;
}
.fs-7s{
    font-size: .7rem;
}
.fs-6s{
    font-size: .6rem;
}
.fs-5s{
    font-size: .5rem;
}
.fs-4s{
    font-size: .4rem;
}

.fw-black{
    font-weight: 900;
}

.timeline-gif{
    margin-top: -3rem;
    height: 17rem;
}

.vertical-timeline-element{
    margin-bottom: 3rem !important;
}

.vertical-timeline-element-content{
    box-shadow: none !important;
}

.gradient-title {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #066df4, #2b7abc, #9d8de5 50%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 150%;
}
.gradient-title-2 {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #898d92, #cacfd3, #898d92 50%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 150%;
}

.subtitle-color{
    color: var(--primary);
    text-shadow: 1px 1px 2px #ffffff;
}

.title-size {
    font-size: calc(1.8rem + 1.5vw) !important;
}
@media (min-width: 768px) {
    .title-size {
        font-size: 3.8rem !important;
    }
}

.title-underline{
    position: relative;
    display: inline;
    background: url(../images/highlight.png);
    background-size: 80% 20px;
    background-repeat: no-repeat;
    background-position: center 1.7rem;
}

@media (min-width: 1200px) {
    .title-underline{
        background-position: center 1.7rem;
    }
}

.title-3d {
    display: inline-block;
    color: #000000;
    position: relative;
    text-shadow: .03em 5em 0 rgb(255, 255, 255);
    transform: skew(-12deg) rotate(-5deg) !important;
}
.title-3d::after {
    content: attr(data-shadow);
    position: absolute;
    top: .06em; left: .06em;
    z-index: -1;
    text-shadow: none;
    background-image: linear-gradient( 45deg, transparent 45%, rgb(24, 24, 23) 45%, rgb(24, 24, 23) 55%, transparent 0 );
    background-size: .05em .05em;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (max-width: 992px) {
    .title-3d {
        transform: skew(-12deg) rotate(-2deg) !important;
    }
}

.cs-title span.title-line{
    display: block;
    position: relative;
    width: 100px;
    height: 2px;
    background-color: var(--primary);
    border-radius: 10px;
}

.cs-title span.title-line::after{
    content: "";
    position: absolute;
    width: 4px;
    height: 2px;
    background-color: #ffffff;
    left: 0px;
    animation: move-line 3.1s infinite 0s linear;
}

.cs-title span.title-line::before{
    content: "";
    position: absolute;
    width: 3px;
    height: 2px;
    background-color: #ffffff;
    left: 0px;
    animation: move-line 3.1s infinite .53s linear;
}

@keyframes move-line {
    0%{
        left: 0px;
    }
    100%{
        left: 100px;
    }
}

#wa_btn-content{
    box-shadow:  0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: transparent;
}

#wa_btn-content p{
    font-size: 12px !important;
}

#wa_btn-content{
    padding: 5px 12px !important;
}
@media screen and (max-width: 576px) {
    #wa_btn-content p{
        display: none !important;
    }
    #wa_btn-content{
        padding: 13px !important;
    }
}

.react-multi-carousel-dot button{
    border-color: #898d92;
    border-width: 1px;
    background-color: #ffffff7f;
}
.react-multi-carousel-dot-list {
    z-index: 3;
}
.react-multi-carousel-dot--active button{
    background-color: #4d92ec;
}

.hero-carousel{
    width: 100%;
    height: 100vh;
}

.hero-carousel .react-multi-carousel-item{
    min-height: 100vh;
    height: auto !important;
}

@media screen and (max-width: 768px) {
    .hero-carousel .react-multi-carousel-track{
        min-height: 80vh;
        align-self: start;
    }

    .hero-carousel .react-multi-carousel-list, .hero-carousel .react-multi-carousel-item{
        min-height: 80vh;
        height: auto !important;
    }

    .hero-carousel{
        min-height: 80vh;
        height: auto !important;
    }
}

.react-responsive-modal-root{
    z-index: 1032;
}

.react-responsive-modal-modal{
    border-radius: 10px;
}













/* ########## custom btn ########### */
.custom-btn{
    border: 2px solid #000;
    position: relative;
    transition: all .4s cubic-bezier(0.86, 0, 0.07, 1);
}

.custom-btn span, .custom-btn i{
    transition: all .5s cubic-bezier(0.86, 0, 0.07, 1);
    transition-delay: .4s;
}

.custom-btn::before{
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0%;
    background-color: var(--primary);
    transition: all .3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

.custom-btn:hover::before{
    width: 100%;
}

.custom-btn:hover span, .custom-btn:hover i{
    color: #fff !important;
}

.custom-btn:hover i {
    transform: translateX(10px);
}

.custom-btn:hover{
    border-color: var(--primary) !important;
}















/* ########## HEADER ########### */

header{
    transition: all .7s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.main-nav-link{
    position: relative;
    transition: all .5s cubic-bezier(0.23, 1, 0.320, 1);
}

.main-nav-link::before, .main-nav-link.active::before, .main-nav-link-special > span::before{
    content: "";
    position: absolute;
    width: 0%;
    height: 3px;
    border-radius: 100px;
    background-color: var(--primary);
    left: 0;
    bottom: 0;
    transition: all .5s cubic-bezier(0.23, 1, 0.320, 1);
}

.main-nav-link:hover, .main-nav-link.active, .main-nav-link-special > span{
    color: var(--primary) !important;
}

.main-nav-link:hover::before, .main-nav-link.active::before, .main-nav-link-special > span::before{
    width: 75%;
}

.main-subnav-parent > div{
    transform: translateY(-20px);
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease;
}

.main-subnav-parent:hover > div{
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    visibility: visible;
}

@media only screen and (max-width: 768px) {
    .main-nav-link{
        font-size: 1.1rem;
        color: #000000 !important;
        display: inline-block;
        flex-grow: 0;
        align-self: flex-start;
    }
}

.header-text-white, .header-text-white.active{
    color: #fff !important;
}

.header-text-black a, .header-text-black span, .header-text-black i{
    color: #000 !important;
}

.header-text-black .main-nav-link.active,  .main-nav-link-special > span{
    color: var(--primary) !important;
}

#mobileNav .accordion-button{
    padding: 0 !important;
}

#mobileNav .accordion-item{
    border: none !important;
}

#mobileNav .accordion-button:focus{
    border: none !important;
    box-shadow: none !important;
}

#mobileNav .accordion-header{
    border: none !important;
}

#mobileNav .accordion-collapse.collapse{
    border: none !important;
}

#mobileNav .accordion-button:not(.collapsed){
    border: none !important;
    background-color: transparent !important;
}

.image-appear-container {
    overflow: hidden;
}

.image-appear-container .image, .image-appear-container .image-2, .image-appear-container .image-3 {
    position: absolute;
    width: auto;
    z-index: -1;
    height: 2rem;
    opacity: 0;
    transition: opacity 2s, transform 2s;
    will-change: transform, opacity;
    animation: moveUp 9s linear infinite;
}

.image-appear-container .image-3{
    height: 3rem;
}

.image-appear-container .image-2, .image-appear-container .image-3{
    animation: moveUpWhitoutRotate 9s linear infinite;
}

@keyframes moveUp {
    0%{transform: translateY(0) rotate(0deg) scale(.4)}
    25%{transform: translateY(-87px) rotate(45deg) scale(.6)}
    50%{transform: translateY(-175px) rotate(90deg) scale(1)}
    100%{transform: translateY(-350px) rotate(180deg) scale(.8)}
}
@keyframes moveUpWhitoutRotate {
    0%{transform: translateY(0) scale(.4)}
    25%{transform: translateY(-87px) scale(.6)}
    50%{transform: translateY(-175px) scale(1)}
    100%{transform: translateY(-350px) scale(.8)}
}

.lottie-size{
    max-width: 40vw;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .lottie-size{
        max-width: 80vw;
        min-width: 40vw;
        max-height: 70vh;
        min-height: 35vh;
    }

    .text-mobile-center{
        text-align: center;
    }
}















/* ###########  FOOTER  ########### */
.animated-footer{
    background: linear-gradient(#ffffff, #ffffffdd, #ffffff80, #ffffff00, #ffffff00), url(../images/footer-bg.jpg);
    background-size: 100% auto;
    margin-top: -30px;
    background-repeat: no-repeat;
    background-position: center bottom;
    overflow: hidden;
}

.animated-footer > div{
    width: calc(100vw/3.5);
    margin-bottom: calc(-100px/3);
}

.animated-footer > div:nth-child(2){
    width: calc(100vw/4);
}

@media only screen and (max-width: 768px) {
    .animated-footer > div{
        width: calc(100vw/2.1);
    }
    .animated-footer > div:nth-child(2){
        /* width: calc(100vw/4); */
        display: none;
    }
}








/* ######### HERO ######## */
.ticker-container{
    max-height: auto !important;
}








/* ######## TIMELINE ######### */
@media only screen and (min-width: 1170px) {
    .timeline-gif{
        margin-top: -4rem;
        display: flex !important;
    }

    .timeline-gif.timeline-gif-end{
        justify-content: end;
    }
    .timeline-gif.timeline-gif-start{
        justify-content: start;
    }

    .vertical-timeline-element{
        margin-bottom: 2rem !important;
    }
}

.vertical-timeline-element-icon {
    -webkit-box-shadow: 0 0 0 4px var(--primary), inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
    box-shadow: 0 0 0 4px var(--primary), inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
}

.timeline-icon{
    width: 80%;
    margin-bottom: -15px;
    margin-left: -5px;
}

@media screen and (max-width: 1170px) {
    .timeline-icon{
        margin-bottom: -10px;
        margin-left: 0px;
    }
}




/* ########### PARAGRAPH ############ */
@media only screen and (min-width: 768px){
    .minimize-div{
        width: 70%;
    }
}








/* ###########  CUSTOMER ########### */

.customer-img img{
    filter: grayscale(100%);
    transition: all .5s ease;
}

.customer-img:hover img{
    filter: grayscale(0%);
}

.mt-lastLine{
    margin-top: -4.55rem !important;
}
.mt-lastLine-2{
    margin-top: -4.25rem !important;
}





/* ############ DESIGN GRAPHIC ############### */
.animation-translate{
    transition: all .7s ease;
    animation: floating-translate 5s infinite normal;
}

@keyframes floating-translate {
    0%{
        transform: translateY(0);
    }
    33%{
        transform: translateY(-15px) translateX(10px);
    }
    66%{
        transform: translateY(-25px) translateX(0);
    }
    100%{
        transform: translateY(0);
    }
}


.animation-rotate{
    transition: all .7s ease;
    animation: floating-rotate 7s infinite normal;
}

@keyframes floating-rotate{
    0%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(-15px) translateX(5px) rotate(90deg);
    }
    40%{
        transform: translateY(0) translateX(10px) rotate(180deg);
    }
    80%{
        transform: translateY(0) translateX(10px) rotate(360deg);
    }
    100%{
        transform: translateY(0) translateX(0) rotate(360deg);
    }
}


.animation-vibrate{
    transition: all .7s ease;
    animation: floating-vibrate 4s infinite normal;
}

@keyframes floating-vibrate{
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(30px) translateX(-17px);
    }
    41%{ transform: rotate(5deg) }
    42%{ transform: rotate(-5deg) }
    43%{ transform: rotate(5deg) }
    44%{ transform: rotate(-5deg) }
    45%{ transform: rotate(5deg) }
    46%{ transform: rotate(-5deg) }
    47%{ transform: rotate(5deg) }
    48%{ transform: rotate(-5deg) }
    49%{ transform: rotate(5deg) }
    50%{ transform: rotate(-5deg) }
    51%{ transform: rotate(5deg) }
    52%{ transform: rotate(-5deg) }
    53%{ transform: rotate(5deg) }
    54%{ transform: rotate(-5deg) }
    55%{ transform: rotate(5deg) }
    56%{ transform: rotate(-5deg) }
    57%{ transform: rotate(5deg) }
    58%{ transform: rotate(-5deg) }
    59%{ transform: rotate(5deg) }
    100%{
        transform: translateY(0) translateX(0);
    }
}

.imgs-float img{
    height: 2.4rem;
}

@media only screen and (max-width: 768px){
    .imgs-float img{
        height: 1.5rem;
    }
}

.top-stic{
    top: 7rem;
}













/* ########## AUDIOVISUAL ############ */
.border-zipped {
    border: 1rem solid var(--primary);
    border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, var(--primary) 6px, var(--primary) 15px, transparent 16px, transparent 20px) 10 / .5rem;
    padding: -10px;
}

#audioVisualCanvas {
    width: 100%;
    height: 300px; /* Ajuster selon vos besoins */
    display: block;
}














/* ############# SERVICES WEB ################ */
.network-container {
    width: 100%;
    height: 100%; /* Ajustez la hauteur selon vos besoins */
    position: relative;
}

canvas {
    width: 100%;
    height: 100%;
}














/* ########### CONTACT ############### */
.contact-card{
    position: relative;
    /* animation: contact-float 3s infinite ease; */
    transition: all .5s ease;
}

@keyframes contact-float {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(4px);
    }
    100%{
        transform: translateY(0);
    }
}

.contact-card .icon{
    transition: all .5s ease;
    z-index: 1;
}

.contact-card .icon::after{
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: var(--primary);
    transition: height .8s ease;
}

.contact-card:hover{
    box-shadow: 0 0 0 0 transparent !important;
}

.contact-card:hover .icon{
    color: #ffffff !important;
}

.contact-card:hover .icon::after{
    height: 100%;
}


















/* ########### REALISATIONS ############# */
.realisation-card{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.realisation-card > div h4{
    transition: all .5s ease;
}
.realisation-card > div p{
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    max-height: 0;
    transition: all .5s ease;
}

.realisation-card:hover > div p{
    opacity: 1;
    transform: translateY(0);
    max-height: 100%;
}

.realisation-card > img{
    transition: all .5s ease-in-out;
}

.realisation-card:hover > img{
    opacity: 1;
    transform: scale(1.1);
    max-height: 100%;
}

.realisation-card:hover .bg-card{
    background: linear-gradient(to top, #000000, #000000bb, #00000083, #00000048, #00000000);
}
.realisation-card .bg-card{
    background: linear-gradient(to top, #00000083, #00000048, #00000000);
}

/* react tab style refactoring */
.nav-item.react-tabs__tab--selected .nav-link {
    color: #fff;
    background-color: var(--primary) !important;
}

.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected){
    transform: translateY(20px);
    opacity: 0;
    transition: opacity .15s linear;
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
    transform: translateX(0);
    opacity: 1;
}

.realisation-grid {
    -webkit-column-count: 4;
    column-count: 4;
    -webkit-column-width: 19%;
    column-width: 19%;
    columns: 19% 4;
    gap: 4px;
}

@media (max-width: 991px) {
    .realisation-grid {
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 576px) {
    .realisation-grid {
        column-count: 2;
        -webkit-column-count: 2;
    }
}
@media (max-width: 378px) {
    .realisation-grid {
        column-count: 1;
        -webkit-column-count: 1;
        column-width: 100%;
        -webkit-column-width: 100%;
        columns: 100% 2;
    }
}

.realisation-grid .realisation-card {
    margin-bottom: 4px;
    transition: all .6s ease;
}

.realisation-card-2{
    min-height: 12rem;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
    animation: animate 1.7s ease-in infinite;
}

@keyframes animate {
    0% {
        transform: translateY(3px);
    }
    
    50% {
        transform: translateY(-3px);
    }

    100% {
        transform: translateY(3px);
    }
}

.realisation-card-2 .img{
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.realisation-card-2 .img > div{
    opacity: 0;
    transform: translateY(200px);
    transition: all .5s ease;
}

.realisation-card-2 .img:hover > img{
    transform: scale(1.1);
}

.realisation-card-2 .img:hover > div{
    background: linear-gradient(to top, #ffffffe1, #ffffff9e, #ffffff69, #ffffff00, #ffffff00, #ffffff00, #ffffff00);
    opacity: 1;
    transform: translateY(0);
}

.rea-bg-1{
    background: url(../images/bg-rea-1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
}
.rea-bg-2{
    background: url(../images/bg-rea-2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
}
.rea-bg-3{
    background: url(../images/bg-rea-3.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
}
.rea-bg-4{
    background: url(../images/bg-rea-4.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
}
.rea-bg-5{
    background: url(../images/bg-rea-5.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
}